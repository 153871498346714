import {
  createContext,
  createMemo,
  createSignal,
  Show,
  useContext,
} from "solid-js";
import { useParams } from "@solidjs/router";

import User from "@repo/models-kikoff/User";
import Logo from "@repo/ui/branding/Logo";
import Button from "@repo/ui/buttons/Button";
import ContainerButton from "@repo/ui/buttons/ContainerButton";
import Card from "@repo/ui/containers/Card";
import ScrollView from "@repo/ui/containers/ScrollView";
import Line from "@repo/ui/decoration/Line";
import ListTile from "@repo/ui/info/ListTile";
import ClickToCopy from "@repo/ui/inputs/ClickToCopy";
import WithKeybind from "@repo/ui/keybind/WithKeybind";
import Constraints from "@repo/ui/layout/Constraints";
import Masonry from "@repo/ui/layout/Masonry";
import Spinner from "@repo/ui/loaders/Spinner";
import Link from "@repo/ui/navigation/Link";
import Address from "@repo/utils/Address";
import FullName from "@repo/utils/FullName";
import Money from "@repo/utils/Money";
import PhoneNumber from "@repo/utils/PhoneNumber";
import UDate from "@repo/utils/UDate";
import Env from "@repo/utils-client/Env";

import Status from "~/components/Status";
import UserSearch from "~/components/UserSearch";
import { useOverlaysController } from "~/overlays";
import {
  ExtendedCreditLine,
  useCreditLines,
} from "~/state/features/creditLines";
import { UserDump, useUsers } from "~/state/features/users";

import ParsedIdv from "./_views/parsedIdv";

import styles from "./[userId].module.scss";

export default function UserPage() {
  const params = useParams<{ userId: User.Id }>();

  const overlays = useOverlaysController();

  const [users, setUsers] = useUsers();

  const user = createMemo(() => users.dumpFor(params.userId));

  return (
    <div class={`${styles["user-page"]} h100% column`}>
      <nav>
        <div class="py-1 px-2 row-center">
          <Link href="/" class="row-center mr-2">
            <div class="icon:medium color:moderate mr-1"></div>
            <Logo icon class="text:heading-4++">
              Admin
            </Logo>
          </Link>
          <UserSearch />
        </div>
        <Line />
      </nav>
      <main class="expand row">
        <div></div>
        <Show when={user()} fallback={<Spinner.Common />}>
          {(user) => (
            <ScrollView class="expand">
              <Constraints class={styles.container} maxWidth="1800px">
                <header class="text:heading-4">
                  {FullName.format(user().pii.name)}{" "}
                  <Status
                    color={user().active ? "var(--success)" : "var(--error"}
                  />
                  {(() => {
                    const action = user().active ? "deactivate" : "activate";
                    return (
                      <WithKeybind alt key={action[0]!.toUpperCase()}>
                        <ContainerButton
                          class="text:regular inline-block color:error underline ml-1.5"
                          onClick={() => {
                            overlays.push("Confirm", {
                              description: `You're about to ${action} ${FullName.format(user().pii.name)}'s account.`,
                              action: () => setUsers[action](params.userId),
                            });
                          }}
                        >
                          {
                            {
                              activate: " Activate",
                              deactivate: " Deactivate",
                            }[action]
                          }
                        </ContainerButton>
                      </WithKeybind>
                    );
                  })()}
                  <Button
                    variant="primary-hard"
                    size="small"
                    class="ml-2 "
                    onClick={() =>
                      setUsers
                        .impersonate(user().id, "ENABLED")
                        .then(() =>
                          window.open(
                            `${Env.resolveUrl(import.meta.env.WEB_ORIGIN)}/dashboard`
                          )
                        )
                    }
                  >
                    Impersonate
                  </Button>
                </header>
                <Card.PropsProvider outline>
                  <UserContext.Provider value={user}>
                    <Masonry minColumnWidth={400} gap={16} alignColumns>
                      <PersonalInformation />
                      {user().creditLines?.map((creditLine) => (
                        <CreditLine creditLine={creditLine} />
                      ))}
                    </Masonry>
                    <IdentityVerification />
                  </UserContext.Provider>
                </Card.PropsProvider>
              </Constraints>
            </ScrollView>
          )}
        </Show>
      </main>
    </div>
  );
}

// Unused for now, may use later
// eslint-disable-next-line no-unused-vars
declare namespace Action {
  type Props = { icon: string; label: string; onClick(): void };
}

function Action({ icon, label, onClick }: D<Action.Props>) {
  return (
    <ContainerButton onClick={onClick} class={styles.action}>
      <div class="icon:medium">{icon}</div>
      <div class="text:mini">{label}</div>
    </ContainerButton>
  );
}

const UserContext = createContext<() => User & Partial<UserDump.Resolved>>();

const useUser = () => useContext(UserContext)!;

function PersonalInformation() {
  const user = useUser();

  return (
    <Card marginCollapsible>
      <Card.Label title="Personal Information" />
      <ListTile.PropsProvider
        align="center"
        propsFor={{
          leading: { class: "icon:medium" },
          trailing: { class: "icon:medium color:moderate-weak" },
        }}
      >
        <ClickToCopy text={user().id}>
          {({ icon }) => (
            <ListTile
              leading=""
              label="Account number"
              title={User.Id.format(user().id)}
              trailing={icon()}
            />
          )}
        </ClickToCopy>
        <ClickToCopy text={user().pii.email}>
          {({ icon }) => (
            <ListTile
              leading=""
              label="Email"
              title={user().pii.email}
              trailing={icon()}
            />
          )}
        </ClickToCopy>
        <ClickToCopy text={user().pii.phone}>
          {({ icon }) => (
            <ListTile
              leading=""
              label="Phone"
              title={PhoneNumber.format(user().pii.phone)}
              trailing={icon()}
            />
          )}
        </ClickToCopy>
        <ListTile
          leading=""
          label="Address"
          title={Address.format(user().pii.address)}
        />
        <ListTile leading="" label="Date Of Birth" title={user().dob} />
      </ListTile.PropsProvider>
    </Card>
  );
}

declare namespace CreditLine {
  type Props = { creditLine: ExtendedCreditLine };
}

function CreditLine({ creditLine }: D<CreditLine.Props>) {
  const overlays = useOverlaysController();

  const [, setCreditLines] = useCreditLines();
  const user = useUser();

  return (
    <Card marginCollapsible>
      <Card.Label title="Credit line" />
      {Object.entries(creditLine).map(function group([key, value]: any) {
        const rendered = (() => {
          if (value == null)
            return <div class="color:moderate-weak">Empty</div>;
          if (key.endsWith("Cents")) return Money.format.cents(value);
          if (value instanceof Date)
            return `${UDate.format(value, "mm/dd/yyyy")} ${UDate.format.time(value, "hh:mm:ss")}`;

          if (typeof value === "object") return value;

          return JSON.stringify(value);
        })();
        if (rendered === value)
          return (
            <details class="my-2">
              <summary>{key}</summary>
              <div class={styles.group}>{Object.entries(value).map(group)}</div>
            </details>
          );
        return (
          <ListTile
            title={key}
            propsFor={{ trailing: { class: styles.value } }}
            trailing={rendered}
          />
        );
      })}
      <div class={styles.actions}>
        <WithKeybind alt key="T">
          <ContainerButton
            class="underline color:primary"
            onClick={() => {
              overlays.push("info/creditLine/Transactions", {
                creditLineId: creditLine.id,
              });
            }}
          >
            Transaction history
          </ContainerButton>
        </WithKeybind>
        <WithKeybind alt key="S">
          <ContainerButton
            class="underline color:primary"
            onClick={() => {
              overlays.push("info/creditLine/Statements", {
                creditLineId: creditLine.id,
              });
            }}
          >
            Statements
          </ContainerButton>
        </WithKeybind>
        <WithKeybind alt key="C">
          <ContainerButton
            class="underline color:error"
            onClick={() => {
              overlays.push("Confirm", {
                description: `You're about to close ${FullName.format(user().pii.name)}'s credit line.`,
                action: () => setCreditLines.close(creditLine.id),
              });
            }}
          >
            Close account
          </ContainerButton>
        </WithKeybind>
      </div>
    </Card>
  );
}

function IdentityVerification() {
  const overlays = useOverlaysController();

  const user = useUser();
  const [, setUser] = useUsers();
  const [raw, setRaw] = createSignal(false);

  return (
    <Card marginCollapsible>
      <Card.Label title="IDV Data" />
      <WithKeybind alt key="R">
        <Button
          variant="primary-soft"
          size="small"
          onClick={() => {
            overlays.push("Confirm", {
              title: `Send to ${FullName.format(user().pii.name)} retry?`,
              action: () => setUser.allowIdvRetry(user().id),
            });
          }}
        >
          Send to retry
        </Button>
      </WithKeybind>
      <Button
        class="ml-1"
        variant="primary-soft"
        size="small"
        onClick={() => setRaw(!raw())}
      >
        {raw() ? "Hide" : "View"} Raw JSON
      </Button>
      <Show
        when={raw()}
        fallback={<ParsedIdv payload={user().rawIdvPayloads} />}
      >
        <pre>{JSON.stringify(user().rawIdvPayloads, null, 2)}</pre>
      </Show>
    </Card>
  );
}
